// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-work-el-wafa-app-js": () => import("./../src/pages/work/el-wafa-app.js" /* webpackChunkName: "component---src-pages-work-el-wafa-app-js" */),
  "component---src-pages-work-el-wafa-website-js": () => import("./../src/pages/work/el-wafa-website.js" /* webpackChunkName: "component---src-pages-work-el-wafa-website-js" */),
  "component---src-pages-work-js": () => import("./../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-social-ape-js": () => import("./../src/pages/work/social-ape.js" /* webpackChunkName: "component---src-pages-work-social-ape-js" */),
  "component---src-pages-work-toasted-buns-js": () => import("./../src/pages/work/toasted-buns.js" /* webpackChunkName: "component---src-pages-work-toasted-buns-js" */),
  "component---src-templates-post-list-js": () => import("./../src/templates/post-list.js" /* webpackChunkName: "component---src-templates-post-list-js" */),
  "component---src-templates-single-post-js": () => import("./../src/templates/single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-tag-posts-js": () => import("./../src/templates/tag-posts.js" /* webpackChunkName: "component---src-templates-tag-posts-js" */)
}

